

const {
    SigningStargateClient,
} = require("@cosmjs/stargate");

const usdcChainId = process.env.VUE_APP_USDC_CHAIN_ID;
const usdcUrl = process.env.VUE_APP_USDC_RPC;

async function signingStargateClient() {
    const offlineSigner = window.getOfflineSigner(usdcChainId);
    let client = await SigningStargateClient.connectWithSigner(usdcUrl, offlineSigner);
    return client;
}

export async function queryTokenFromUsdc() {
    let accountInfo = await getAccountInfo()
    console.log("wxl ---  accountInfo", accountInfo)
    if (accountInfo) {
        let client = await signingStargateClient();
        let allBal = await client.getAllBalances(accountInfo.bech32Address);
        console.log("xxl allBal : ", allBal);
        return allBal;
    } else {
        return ''
    }
}

export async function getAccountInfo() {
    try {
        // keplr 检测
        await window.keplr.enable(usdcChainId);
        // get account
        const account = await window.keplr.getKey(usdcChainId);
        return account;
    } catch (error) {
        console.log(error);
    }
}

export async function getAddress() {
    const offlineSigner = window.getOfflineSigner(usdcChainId);
    const accounts = await offlineSigner.getAccounts();
    return accounts[0];
}

export async function ibcTransferFromUSDC(
    source_port,
    source_channel,
    token,
    receiver,
    timeout_timestamp
) {
    let denom =  process.env.VUE_APP_USDC_DENOM
    const fee = {
        amount: [{
            denom: denom,
            amount: 3960,
        }],
        gas: "200000",
    };

    try {
        let client = await signingStargateClient();
        let addressInfo = await getAddress();

        // let sender = "cosmos1gjmwgj0lduw0d75xggy3394ahq8fd2mncle8tn";

        console.log("xxl sendIbcTokens param is ",
            [addressInfo,
                receiver,
                token,
                source_port, source_channel,
                undefined, timeout_timestamp, fee]
        );
        let tx = await client.sendIbcTokens(
            addressInfo.address,
            receiver,
            token,
            source_port, source_channel,
            undefined,timeout_timestamp, fee
        );
        return tx;
    } catch (error) {
        console.log("ibcTransferFromCosmos error",error);
        return { code: 500, msg: error };
    }
}


export async function quiryTx(tx) {
    try {
        let client = await signingStargateClient();
        let result = await client.queryClient.tx.getTx(tx);
        return result;
    } catch (e) {
        console.log(e);
    }
}


export async function addUSDCNet() {
    if (!window.getOfflineSigner || !window.keplr) {
        alert("Please install keplr extension");
    } else {
        if (window.keplr.experimentalSuggestChain) {
            try {
console.log("addUSDCNet", process.env.VUE_APP_USDC_CHAIN_ID,process.env.VUE_APP_USDC_RPC);
                await window.keplr.experimentalSuggestChain(
                    {
                        // Chain-id of the Osmosis chain.
                        chainId: process.env.VUE_APP_USDC_CHAIN_ID,
                        chainName: "usdc noble",
                        rpc: process.env.VUE_APP_USDC_RPC,
                        rest: process.env.VUE_APP_USDC_REST,
                        stakeCurrency: {
                            coinDenom: "USDC",
                            coinMinimalDenom: "uusdc",
                            coinDecimals: 6,
                        },
                      
                        bip44: {
                          
                            coinType: 118,
                        },
                      
                        bech32Config: {
                            bech32PrefixAccAddr: "noble",
                            bech32PrefixAccPub: "noblepub",
                            bech32PrefixValAddr: "noblevaloper",
                            bech32PrefixValPub: "noblevaloperpub",
                            bech32PrefixConsAddr: "noblevalcons",
                            bech32PrefixConsPub: "noblevalconspub"
                          },
                          currencies: [
                            {
                              coinDenom: "USDC",
                              coinMinimalDenom: "uusdc",
                              coinDecimals: 6,
                              coinGeckoId: "usd-coin",
                              coinImageUrl: "https://raw.githubusercontent.com/chainapsis/keplr-chain-registry/main/images/noble/uusdc.png"
                            },
                            {
                              coinDenom: "FRNZ",
                              coinMinimalDenom: "ufrienzies",
                              coinDecimals: 6,
                              coinImageUrl: "https://raw.githubusercontent.com/chainapsis/keplr-chain-registry/main/images/noble/ufrienzies.png"
                            }
                          ],
                          feeCurrencies: [
                            {
                              coinDenom: "USDC",
                              coinMinimalDenom: "uusdc",
                              coinDecimals: 6,
                              coinGeckoId: "usd-coin",
                              coinImageUrl: "https://raw.githubusercontent.com/chainapsis/keplr-chain-registry/main/images/noble/uusdc.png",
                              gasPriceStep: {
                                "low": 0.1,
                                "average": 0.1,
                                "high": 0.2
                              }
                            },
                           
                          ],
                     
                    });
            } catch(e) {
                console.log('sssssssssss -----',e);
                alert("Failed to suggest the chain");
                // location.reload();
            }
        } else {
            alert("Please use the recent version of keplr extension");
        }
    }
}