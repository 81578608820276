<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        src="@/assets/btn_close.png"
        alt=""
      />
      <div class="top">
		Before connecting, please ensure that you have imported your Uptick Chain seed phrase into Keplr and you are logged into the correct account before attempting to deposit via IBC to your Uptick Chain account.
      </div>
      <div class="footer">
        <button
          class="sub v-btn uc"
          submit
          @click="subCommit"
        >
        Confirm
        </button>
      </div>
    </v-card>

    <uComponents ref="ucom"></uComponents>
  </div>
</template>
<script>
export default {
  data: () => ({
    newOpen: true,
  
  }),
  
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  mounted() {

  },
  methods: {
    async subCommit() {
       this.$emit("getOpen", false);
    },
    closeDialog() {
      this.open = false;
      this.$emit("getOpen", this.open);
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  min-height: 239px;
  width: 600px;

  .top {
    display: flex;
    flex-direction: column;
	 margin: 50px 30px 10px 30px ;
	font-weight: bold!important;
	font-size: 20px;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0px;
	color: #270645;
	text-align: center;
	



    .content {
      margin: 50px auto 25px;
      width: 226px;
      min-height: 22px;
      font-family: Helvetica;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      text-align: center;
    }
  }

  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }

  .footer {
    margin: 0 auto;

    .sub {
      margin: 30px auto;
      width: 450px;
      height: 51px;
      // background-image: linear-gradient(
      //     90deg,
      //     #d300fd 0%,
      //     #a439fe 26%,
      //     #7471ff 52%,
      //     #00fdcf 100%
      //   ),
      //   linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
      font-weight: bold;;
      font-size: 20px;
      color: #ffffff;
    }
  }
  &.mobile {
    .footer {
      width: 100%;
      margin: 0;
      .sub {
        margin-left: 10%;
        width: 80%;
      }
    }
  }
}

img {
  display: inline-block;
  width: 126px;
  height: 126px;
  background-color: #8a73ff;
  border-radius: 5px;
  margin: 1px 22px 10px 52px;
}
.sub-dis {
  position: relative;
  pointer-events: none;
  background-image: linear-gradient(#766983, #766983),
    linear-gradient(#270645, #270645) !important;
  background-blend-mode: normal, normal;
  border-radius: 25px;
  opacity: 0.9;
}
.sub-dis::after {
  content: "";
  background-image: url(../../assets/loading.gif);
  background-size: 100%;
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: 110px;

  // box-shadow: 0 0 10px #fff;
  // border-radius: 50%;
}
</style>

