const iris =  require("@irisnet/irishub-sdk");

const BigNumber = require('big-number');
const long = require('long');
const chainId = process.env.VUE_APP_IRIS_CHAIN_ID;
const md5 = require('md5');

import { 
  getRanHex
} from "../utils/helper";

//构建 iris-sdk 配置
let config = {
    node: process.env.VUE_APP_IRIS_RPC,
    chainNetwork: iris.types.ChainNetwork.Iris,
    chainId: chainId,
    //gas: process.env.VUE_APP_IRIS_BASE_GAS,
    gas: '250000',
    fee: { denom: process.env.VUE_APP_IRIS_DENOM, amount: '75000' },
};

// irisnet sdk 初始化
const client = iris
  .newClient(config)
  .withKeyDAO({write:()=>{ 
  },read:()=>{
    return '';
  }})
  .withRpcConfig({ timeout: 50000 });

export async function getMyBalance(){
debugger
  let accountInfo = await getAccountInfo()
  if(accountInfo != null)
  {
    let result = await client.bank.queryBalance(accountInfo.bech32Address,'uiris')
    let balance = result["balance"]
    balance["format"] = (balance["amount"]/1000000).toFixed(2);
    let mount = balance.format
    
    localStorage.setItem("key_balance",mount);
    return balance;
  }
  return 0;

  
}

// let tx = await issueDenomAndMint(
//   orgName,
//   sender,
//   recipient,
//   uri,
//   data,
//   amount,
//   fee,
//   addminAddress,
// );

export async function issueDenomAndMint(
  orgName,
  sender,
  recipient,
  uri,
  data,
  amount,
  fee,
  adminAddress,
){

  let accountInfo = await getAccountInfo()  
  let msgs = [];
  let name = getDenomName(orgName,accountInfo.bech32Address);
  let id = getDenomId(name)

  let value =   {
    id,name,schema:getDenomSchema(),sender
  } 
  let msg =  {
    type: iris.types.TxType.MsgIssueDenom,
    value
  }
  msgs.push(msg);

  for(var i = 0 ;i < amount ;i ++){

    msg =  {
      type: iris.types.TxType.MsgMintNFT,
      value: {
          id: getNftId(),
          denom_id: id,
          name: name,
          uri: uri,
          data: data,
          sender: accountInfo.bech32Address,
          recipient: recipient
      }
    }
    msgs.push(msg);

  }

  console.log("xxl ---");
  console.log(msgs);
if(fee > 0 ){
  msgs = addSendMsg(msgs,accountInfo.bech32Address,adminAddress,fee);
  }
  let txInfo = await signAndBroadcastTx(accountInfo,msgs);
  return {
    txInfo,
    denomInfo:msgs
  }


}

export async function issueDenom(
  orgName,
  sender,
  fee,
  adminAddress
){

console.log("xxl come to issueDenom 11");
console.log([
  orgName,
  sender,
  fee,
  adminAddress
])


  let accountInfo = await getAccountInfo()  
  let msgs = [];
  let name = getDenomName(orgName,accountInfo.bech32Address);
  let id = getDenomId(name)

  let value =   {
    id,
    name,
    schema:getDenomSchema(),
    sender
  } 

  let msg =  {
    type: iris.types.TxType.MsgIssueDenom,
    value
  }
  msgs.push(msg);
  msgs = addSendMsg(msgs,accountInfo.bech32Address,adminAddress,fee);

  //xxl accountInfo 
  console.log("xxl accountInfo msgs ... " );
  console.log(accountInfo);
  console.log(msgs);
  let txInfo = await signAndBroadcastTx(accountInfo,msgs);

  return {
    txInfo,
    denomInfo:value
  }

}

export async function tranferWithMemo(memo,amount,fee,adminAddress){

  let accountInfo = await getAccountInfo()
  let msgs = [];
  msgs = addSendMsg(msgs,accountInfo.bech32Address,adminAddress,amount);
  msgs = addSendMsg(msgs,accountInfo.bech32Address,adminAddress,fee);
  
  //xxl tranferWithMemo
  console.log("xxl tranferWithMemo msgs");
  console.log(msgs);

  await signAndBroadcastTx(accountInfo,msgs,memo);

}

export async function transferNft(nftIds,denomId,sender,recipient,fee,adminAddress,memo){
  
  let accountInfo = await getAccountInfo()
  let msgs = [];
  let len = nftIds.length;
  for(var i= 0 ;i < len ;i ++){
   let  msg = 
    {
      type:iris.types.TxType.MsgTransferNFT,
      value:{
        id:nftIds[i],
        denom_id:denomId,
        sender,
        recipient
      }
    }
    msgs.push(msg)
  }
  if(fee > 0 )
  msgs = addSendMsg(msgs,accountInfo.bech32Address,adminAddress,fee);
  
  //xxl transferNft
  console.log("xxl transferNft msgs");
  console.log(msgs);

  return await signAndBroadcastTx(accountInfo,msgs,memo);

}

export async function orderPay(fee,recipient,memo){
  let accountInfo = await getAccountInfo()
  let msgs = [];
  msgs = addSendMsg(msgs,accountInfo.bech32Address,recipient,fee);
  
  //xxl transferNft
  console.log("xxl transferFee msgs");
  console.log(msgs);

  return await signAndBroadcastTx(accountInfo,msgs,memo);

}



export async function mintNFT(
    recipient,
    denomId,
    denomName,
    uri,
    data,
    amount,
    fee,
    adminAddress
  ){

  if (window.keplr) {
    try {

      let accountInfo = await getAccountInfo()

      console.log("xxl mintNFT 1....");
      console.log(amount);
      console.log("xxl mintNFT 2....");
      
        // 构建 MintNFT Message 结构
        let msgs = [];
        for(var i = 0 ;i < amount ;i ++){

          let msg =  {
            type: iris.types.TxType.MsgMintNFT,
            value: {
                id: getNftId(),
                denom_id: denomId,
                name: denomName,
                uri: uri,
                data: data,
                sender: accountInfo.bech32Address,
                recipient: recipient
            }
          }
          msgs.push(msg);

        }

        console.log("xxl ---");
        console.log(msgs);

        msgs = addSendMsg(msgs,accountInfo.bech32Address,adminAddress,fee);
        let txInfo = await signAndBroadcastTx(accountInfo,msgs);
        return {
          txInfo,
          denomInfo:msgs
        }
    } catch (error) {
        console.log(error);
        console.log('mintNFT error');
    }
  } else {
    console.log('请连接kepla钱包 1！');
  }

}

// export default {
//   getMyBalance, issueDenomAndMint, transferNft, orderPay
// }

function getDenomName(name,address){

  let denomName = name + "_" + Math.floor(Date.now() / 1000) + "_" + address.substr(address.length - 4);
  return denomName;

}

function getDenomId(name){

  let preFix = process.env.VUE_APP_PREFIX;
  return preFix + md5(name);

}

function getDenomSchema(){

  let json = {"type":"/uptick.souvenirCard"};
  return JSON.stringify(json);

}


function getNftId(){

  let preFix = process.env.VUE_APP_PREFIX;
  return preFix + getRanHex(16);

}

function addSendMsg(msgs,fromAddress,adminAddres,fee){

  let retMsgs = msgs;
  let bigFee = BigNumber(fee * 1000000)
  const amount  = [{denom: process.env.VUE_APP_IRIS_DENOM,amount: bigFee.toString()}];

  let feeMsg = {
    type:iris.types.TxType.MsgSend,
    value:{
        from_address:fromAddress,
        to_address:adminAddres,
        amount
    }
  }
  retMsgs.push(feeMsg)

  return retMsgs;

}

export async function getUserName(){
  await window.keplr.enable(chainId);
          // get accountInfo
  const accountKeplr = await window.keplr.getKey(chainId); 
  return accountKeplr.name
}

export async function getAccountInfo() {


    try {
		
          await window.keplr.enable(chainId);
          // get accountInfo
          const accountKeplr = await window.keplr.getKey(chainId); 
          console.log("xxl accountKeplr 000------");
          console.log(accountKeplr);

          const offlineSigner = window.getOfflineSigner(chainId);
          const accounts = await offlineSigner.getAccounts();

          console.log("xxl accounts 011------");
          console.log(accounts);
          

          let accountIris = await client.auth.queryAccount(accountKeplr.bech32Address);

          console.log("xxl accountIris 111------");
          console.log(accountIris);

          return {
            address:accountKeplr["address"],
            bech32Address:accountKeplr["bech32Address"],
            pubKey:accountKeplr["pubKey"],
            accountNumber:accountIris["accountNumber"],
            sequence:accountIris["sequence"]
          }
      }catch (error) {
            console.log(error);
            console.log('denied getAccountInfo');
      }

}

async function signAndBroadcastTx(accountInfo,msgs,memo= ' '){

  try {

      console.log("0");
      //set fee
      let txLen = msgs.length;
      let pulsFee = BigNumber(process.env.VUE_APP_IRIS_STEP_GAS)
                    .multiply(txLen - 1)
		let baseGas=process.env.VUE_APP_IRIS_BASE_GAS
		if(txLen==1){
					baseGas=150000	
					}
      let totalGas = BigNumber(baseGas)
                    .plus(pulsFee)
                    .toString();

      console.log("xxl totalGas 0....");
      console.log(totalGas);
      console.log("xxl totalGas 1....");    


      client.withGas(totalGas);
      let totalFeeAmount = BigNumber(totalGas).divide(10).multiply(3).toString()
      let newFee = { denom: process.env.VUE_APP_IRIS_DENOM, amount: totalFeeAmount};

      console.log("xxl newFee 0....");
      console.log(newFee);
      console.log("xxl newFee 1....");
      client.withFee(newFee);

      client.auth.defaultStdFee ={
          "amount": [newFee],
          "gasLimit": totalGas
      }
      //


      console.log("memo -----------------")
      console.log(memo)

      // 构建 sdk base Tx
      let bTx = {};
      bTx.account_number = accountInfo.accountNumber;
      // xxl 0107 fix sequence is 0 bug
      //bTx.sequence = accountInfo.sequence == 0 ? 1:accountInfo.sequence;
      bTx.sequence = accountInfo.sequence + "";
      bTx.memo = memo;

      console.log(1);
      // 构建 sdk 离线签名结构
      console.log("xxl msgs 0....");
      console.log(msgs);
      console.log("xxl msgs 1....");

      //
      let tx_o = client.tx.buildTx(msgs, bTx);
      console.log(Buffer.from(accountInfo.pubKey).toString('hex'));
      tx_o.setPubKey(Buffer.from(accountInfo.pubKey).toString('hex'));

      console.log(1.2);
      let signDoc = tx_o.getSignDoc(bTx.account_number, chainId);

      console.log(2);
      // 构建 keplr 离线签名结构
      let keplr_signDoc_obj = {
          bodyBytes: signDoc.getBodyBytes(),
          authInfoBytes: signDoc.getAuthInfoBytes(),
          chainId: chainId,
          accountNumber: new long(signDoc.getAccountNumber())
      };

      console.log(3);
      console.log([
        keplr_signDoc_obj.chainId,accountInfo.bech32Address,keplr_signDoc_obj
      ])
      // keplr 签名
      let s = await window.keplr.signDirect(keplr_signDoc_obj.chainId,accountInfo.bech32Address,keplr_signDoc_obj);

      console.log(4);
      // 将 keplr 签名整合到 sdk 离线签名结构
      tx_o.addSignature(s.signature.signature);
      tx_o.body = iris.types.tx_tx_pb.TxBody.deserializeBinary(s.signed.bodyBytes);

      console.log(5);
      console.log(tx_o);

      // sdk broadcast tx
      //let res = await client.tx.broadcast(tx_o, iris.types.BroadcastMode.Commit);
      let res = await client.tx.broadcast(tx_o, iris.types.BroadcastMode.Async);
      console.log('res:',res);

      return res;

  }catch (error) {
        console.log(error);
        console.log('signAndBroadcastTx error');
    }
}


