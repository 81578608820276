<template>
  <div   :class="{ rootHome: true, mobile: isMobile }">
   <div class="uptick">
     <div class="text">You are using the IBC Transfer service provided by Uptick Network. This is one of the general services provided by Uptick Network. You need to connect to your Keplr wallet extension to run this service. So far, this service can help you smoothly use the multi-token function in Uptick Mainnet Marketplace. </div> <br/>
     <div class="text">You are recommended to initiate an IBC transfer with the COSMOS/IRISnet addresses generated by the same seeds in Uptick Mainnet Marketplace. Otherwise, you need to additionally transfer the UPTICK-IRIS/UPTICK-ATOM to your Uptick mainnet address, which can be found in your profile in Uptick Mainnet Marketplace.</div>
     <button class="claim " @click="connectWallet">Connect Keplr Wallet</button>
   </div>
    <v-dialog v-model="openWallet">
         <wallet  @getOpen="Open" ></wallet>
      </v-dialog>
       <v-dialog v-model="openSold">
         <SoldOut  @getOpen="openSoldOut" ></SoldOut>
      </v-dialog>
  </div>
  
  
</template>

<script>
 import Wallet from "@/components/popup/wallet";
 import SoldOut from "@/components/popup/soldout";
import {  addUptickNet,getAddress as getAddressUptick } from "../../keplr/uptick/wallet";
import { addCosmosNet,getAddress as getAddressAtom } from "../../keplr/cosmos/wallet";
import { getAddress as getAddressIRIS,addIRISNet } from "../../keplr/irishub/wallet";


export default {
  name: "Home",
  components: {
    Wallet,SoldOut
  },
  data: function() {
    return{
       openWallet: false,
       openSold:false
  
    }
  },
  methods: {
     
      connectWallet: function () {
            this.openSold = true 
     },
      //弹框关闭后获取open值
         Open(e) {
            this.openWallet = e;
         },
         openSoldOut(e){
           this.openSold = e;
            this.openWallet = true;

         }
    

  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  async mounted() {
    
        await addUptickNet();
        await addCosmosNet();
        await addIRISNet();
  },
  
};
</script>

<style lang="scss" scoped>
.rootHome{
  width: 100%;
   min-height: 500px;
   display: flex;
		justify-content: center;
		align-items: center;
  .uptick{
    margin: 0 170px;
    .text{
        width: 885px;
        text-align: center;
        font-family: Helvetica;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
    }
    .claim { 
       cursor: pointer;
          width: 276px;
      margin: 47px 300px;
        height: 51px;
          border-radius: 25px;
        background-image: linear-gradient(#270645, #270645),
          linear-gradient(
            90deg,
            #d300fd 0%,
            #a439fe 26%,
            #7471ff 52%,
            #00fdcf 100%
          ),
          linear-gradient(#270645, #270645);
          background-blend-mode: normal, normal, normal;   
          font-family:Helvetica;
          font-size: 21px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 51px;
          letter-spacing: 0px;
          color: #ffffff;
           
        }

  }
  &.mobile{
    .uptick{
      text-align: center;
      .text{
         width: 340px;

      }
      .claim{
        margin: 47px 40px;

      }
    }

  }
 
}
</style>
