import { wallet as keplrWallet } from "@/keplr";
// import Web3 from 'web3';
// const Web3 = require('web3');
import store from "../store/index.js"
import { getMyBalance  } from "../keplr/wallet.js";
import { getAddress as getAddressUptick } from "../keplr/uptick/wallet";
import { getAddress as getAddressAtom } from "../keplr/cosmos/wallet";
import { getAddress as getAddressIRIS } from "../keplr/irishub/wallet";
import { getAddress as getAddressUSDC } from "../keplr/usdc/wallet";

export const getkeplaWeb3 = async () => {
	let account = await keplrWallet().getAccountInfo();
	return account.bech32Address
	
};



// import { wallet as evmWallet } from "@/metaMask";
// export const getMatamaskWeb3 = async () => {
//     let account = await evmWallet().getAccountInfo();
//     let address = await account.getAddress();
//     return address;
// };


export const keplrKeystoreChange = async () => {
	console.log("wxl --- cccccccccccc")
    
	window.removeEventListener("keplr_keystorechange", keplrKeystoreChange);
	//
	let UptickObj = await getAddressUptick();
	let AtomObj = await getAddressAtom();
	let irisObj = await getAddressIRIS();
	let usdcObj = await getAddressUSDC();
	
	let UptickAddress = UptickObj.address;
	let AtomAddress = AtomObj.address;
	let irisAddress=irisObj.address
	let usdcAddress = usdcObj.address
	let IrisTokenAddress = {
	   UptickAddress:UptickAddress,
	   AtomAddress:AtomAddress,
	   IrisAddress:irisAddress,
	   UsdcAddress:usdcAddress
  }

	console.log("wxl --- getAddress",UptickObj,AtomObj,irisObj)
  localStorage.setItem("key_tokenAddress", JSON.stringify(IrisTokenAddress));

	let account = await getkeplaWeb3();
	let balance = await getMyBalance();
	let mount = balance.format

	localStorage.setItem("key_balance", mount);
let accounts 
	let json = window.localStorage.getItem("key_user");
	if(json){
	let data = JSON.parse(json);
	 accounts = data.did
	}

debugger
	localStorage.setItem("key_user", JSON.stringify({ user: true, did: account }));

	store.commit('SET_DID', account)
  

	if (account != accounts)
		location.reload();
}
