


const {
    SigningStargateClient,
} = require("@cosmjs/stargate");

const chainId = process.env.VUE_APP_IRIS_CHAIN_ID;
const cosmUrl = window.location.protocol + "//" + window.location.host + "/irishub";

async function signingStargateClient() {
    const offlineSigner = window.getOfflineSigner(chainId);
    let client = await SigningStargateClient.connectWithSigner(cosmUrl, offlineSigner);
    return client;
}

export async function queryTokenFromIRIS() {
    let accountInfo = await getAccountInfo()
    console.log("wxl ---  accountInfo", accountInfo)
    if (accountInfo) {
        let client = await signingStargateClient();
        let allBal = await client.getAllBalances(accountInfo.bech32Address);
        console.log("xxl allBal : ", allBal);
        return allBal;
    } else {
        return ''
    }
}

export async function getAccountInfo() {
    try {
        // keplr 检测
        await window.keplr.enable(chainId);
        // get account
        const account = await window.keplr.getKey(chainId);
        return account;
    } catch (error) {
        console.log(error);
    }
}

export async function getAddress() {
    const offlineSigner = window.getOfflineSigner(chainId);
    const accounts = await offlineSigner.getAccounts();
    return accounts[0];
}

export async function ibcTransferFromIRIS(
    source_port,
    source_channel,
    token,
    receiver,
    timeout_timestamp
) {
    let denom = process.env.VUE_APP_IRIS_DENOM
    const fee = {
        amount: [{
            denom: denom,
            amount:'3960',
        }],
        gas: "110000",
    };

    try {
        let client = await signingStargateClient();
        let addressInfo = await getAddress();


        console.log("xxl sendIbcTokens param is ",
            [addressInfo,
                receiver,
                token,
                source_port, source_channel,
                undefined, timeout_timestamp, fee]
        );
		debugger
		console.log(timeout_timestamp)
        let tx = await client.sendIbcTokens(
            addressInfo.address,
            receiver,
            token,
            source_port, source_channel,
            undefined, timeout_timestamp , fee
        );
        return tx;
    } catch (error) {
        console.log("ibcTransferFromCosmos error",error);
        return { code: 500, msg: error };
    }
}


export async function quiryTx(tx) {
    try {
        let client = await signingStargateClient();
        let result = await client.queryClient.tx.getTx(tx);
        return result;
    } catch (e) {
        console.log(e);
    }
}

export async function addIRISNet() {
    if (!window.getOfflineSigner || !window.keplr) {
        alert("Please install keplr extension");
    } else {
        if (window.keplr.experimentalSuggestChain) {
            try {

                await window.keplr.experimentalSuggestChain(
                    {
                        // Chain-id of the Osmosis chain.
                        chainId: process.env.VUE_APP_IRIS_CHAIN_ID,
                        chainName: "IRISnet nyancat",
                        rpc:"https://rpc.nyancat.rainbow.one/",
                         rest:'https://lcd.nyancat.irisnet.org/',
                          stakeCurrency:{
                                coinDenom:'NYAN',
                                coinMinimalDenom:'unyan',
                                coinDecimals:6
                            },
                            bip44:{
                                coinType: 118,
                            },
                            bech32Config:{
                                bech32PrefixAccAddr: "iaa",
                                bech32PrefixAccPub: "iaapub",
                                bech32PrefixValAddr: "iaavaloper",
                                bech32PrefixValPub: "iaavaloperpub",
                                bech32PrefixConsAddr: "iaavalcons",
                                bech32PrefixConsPub: "iaavalconspub"
                            },
                            currencies:[
                                {
                                    coinDenom: "NYAN",
                                    coinMinimalDenom: "unyan",
                                    coinDecimals:6
                                }
                            ],
                            feeCurrencies:[
                                {
                                    coinDenom: "NYAN",
                                    coinMinimalDenom: "unyan",
                                    coinDecimals:6
                                }
                            ],
                            coinType: 118,
                            gasPriceStep: {
                                low:1,
                                average: 2,
                                high: 3
                            }

                    });
            } catch {
                alert("Failed to suggest the chain");
                // location.reload();
            }
        } else {
            alert("Please use the recent version of keplr extension");
        }
    }
}


