<template>
  <img
    alt="logo"
    src="@/assets/image/uptick network_logo.svg"
    :height="height"
    v-bind="$attrs"
    v-on="$listeners"
  
    :class="{ logo: true }"
  />
</template>

<script lang="js">

export default {
  name: 'Logo',
  inheritAttrs: false,
  
  props: {
      simple: Boolean,
      height: [String, Number]
  },

  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    }
  },

  methods:{


  }
};
</script>

<style lang="scss" scoped>
.logo {
  object-fit: cover;
  object-position: left;
  cursor:pointer;
}

</style>
