<template>
   <div class="top" style="z-index: 201;position: sticky;
    top: 0px;">
      <v-expand-transition>
         <v-main tag="div" class="drawer-nav" :elevation="3" v-if="value" absolute>
        
          
         </v-main>
      </v-expand-transition>
      <v-app-bar
          app
          absolute
          hide-on-scroll
          :scroll-target="`#${containerId}`"
          :fixed="value"
          class="pa-0"
          color="#fff"
          :elevation="0"
          :height="height"
      >
         <v-container>
            <div  class="d-flex align-center py-0  px-lg-0">
               <Logo height="43px" :simple="isMobile" style="margin-right: 15px;" :class="{mobileLogo:isMobile}"/>
               <!-- <LangSelect class="ml-lg-2 langselect" style="z-index: 202;" :class="{mobileLang:isMobile}"/> -->
            </div>
         
         </v-container>
      </v-app-bar>
      <v-snackbar
          v-model="isShow"
          color="error"
          centered
          timeout="2000"
          style="opacity: 0.8;"
      ><span style="color:white;font-size:20px;">{{title}}</span></v-snackbar>
   </div>
</template>

<script lang="js">
   import Logo from '@/components/logo/index.vue';

   // import {keplrKeystoreChange} from "../utils/getAccount.js";
 
   // import {getAddress as getAddressUptick } from "../keplr/uptick/wallet";
   // import {getAddress as getAddressAtom } from "../keplr/cosmos/wallet";

   export default {
      name: 'Header',
      components: { Logo },
      props: {
         value: Boolean,
         containerId: String
      },
      data: function () {
         return {
              Banprompt:false,
            openWallet: false,
            navOpen: false,
            userCenterOpen: false,
            isShow:false,
            title:'',
            user: null,
			   keyChain: "IRIS",
            showCreate: false,
            openCreateChoose:false,
            isShowPool:'false'
         };
      },
      computed: {
         isMobile: function () {
            return this.$vuetify.breakpoint.mobile;
         },
         height: function () {
            if ((this.$route.name === 'Auctionlist' || this.$route.name === 'Marketplace') && this.isMobile) {
               return 180
            }
            return  100;
         },
      },
	  async mounted() {
  



	  },
      methods: {
       
         connectWallet: function () {
			 let key = localStorage.getItem("KEY_CHAIN");
             if(key) this.keyChain = key;

            this.openWallet = true
            this.user = false
         },
          openBanprompt(e){
         this.Banprompt = e
      },
         open: function () {
            console.log(1)
            this.navOpen = true;
            this.$emit('input', true);
         },
         close: function () {
            this.navOpen = false;
            this.$emit('input', false);
         },
         //弹框关闭后获取open值
         Open(e) {
            this.openWallet = e;
         },
         OpenCreateChoose(e){
            this.openCreateChoose = e
         },
         Status(s, account){
            this.user=s;
            this.setShowCreate();
			   localStorage.setItem("key_user", JSON.stringify({user:this.user,did:this.$store.state.did,bscAddress:account}));
         },
      
        async setShowCreate() {
            // if(this.$store.state.did) {
            //     let param = {address: this.$store.state.did  };
            //     let myInfo = await api.home.loopringUserInfo(param);
            //     localStorage.setItem("key_user_grade", JSON.stringify({  grade: myInfo.data.grade}));
            //     if(myInfo.data) {
            //         this.showCreate = true;
            //     } else {
            //         let grade = myInfo.data.grade;
            //         if(grade == 2 || grade == 4) {
            //             this.showCreate = false;
            //         } else {
            //             this.showCreate = true;
            //         }
            //     }
            // }
            
        }
      }
   };
</script>

<style lang="scss" scoped>
   .top {
      letter-spacing: 0px;

      .drawer-nav {
         height: 100vh;
      }
   }
   .langselect{
        &::v-deep .v-chip {
    box-shadow: none;
    border: none;
    margin-bottom: 2px;
    font-weight: bold;
  }
   }

   .creatbtn{
      
      font-family: MicrosoftYaHei-Bold;
	   font-size: 15px;
	   font-weight: bold;
	   font-stretch: normal;
	   line-height: 100px;
	   letter-spacing: 0px;
	   color: #ffffff;
   }

   .mobileLogo{
      margin-left:10px;
   }

   .mobileChain{
      // border: solid 1px red;
      margin-left:-12px;
      margin-right: 3px;
   }
</style>
