import {
    SigningStargateClient,
    coins
} from '@uptsmart/stargate'
//import { SigningStargateClient } from '@/uptick/cosmjs/packages/stargate';
import {
    CosmWasmClient
} from "@cosmjs/cosmwasm-stargate";

import { getRanHex } from "@/utils/helper";
//import { coins } from "@cosmjs/stargate";

import Web3 from 'web3'
const web3Obj = new Web3();

const md5 = require('md5');
const chainId = process.env.VUE_APP_UPTICK_CHAIN_ID;
const url = window.location.protocol + "//" + window.location.host + "/uptick";
const rest = process.env.VUE_APP_UPTICK_REST



export async function queryTokenFromUptick() {
    let accountInfo = await getAccountInfo()
    console.log("wxl ---  accountInfo", accountInfo)
    if (accountInfo) {
        let client = await signingStargateClient();
        let allBal = await client.getAllBalances(accountInfo.bech32Address);
        console.log("xxl allBal : ", allBal);
        return allBal;
    } else {
        return ''
    }
}

export async function getMyBalance() {
    let account = await getAccountInfo();

    let client = await wasmClient();
    let prefix=process.env.VUE_APP_AUPTICK_PREFIX
    let balance = await client.getBalance(account.bech32Address,prefix);

    let amt = web3Obj.utils.fromWei(balance["amount"], "ether");
    balance["format"] = parseFloat(amt).toFixed(2);
    let mount = balance.format;

    localStorage.setItem("key_balance", mount);
    return balance;
}

export async function getAddress() {
    const offlineSigner = window.getOfflineSigner(chainId);
    const accounts = await offlineSigner.getAccounts();
    return accounts[0];
}

export async function ibcTransferFromUptick(
    source_port,
    source_channel,
    token,
    receiver,
    timeout_timestamp
) {
    console.log("xxl come to ibc Transfer Uptick ...");

    let addressInfo = await getAddress();
    // let sender = "uptick17d0c8nedfzlytj0kcs80rh3fk8el58fvnw8pmw";
    // let receiver = "cosmos1gjmwgj0lduw0d75xggy3394ahq8fd2mncle8tn";

    console.log("ibc transfer cosmos");

    // let timespan = (new Date().getTime() + 360000) * 1000000
    const fee = {
        amount: [{
            denom: 'auptick',
            amount: 2000,
        }],
        gas: "10000000000",
    };

    console.log("xxl client :: ---- 1");
    let client = await signingStargateClient();
    console.log("xxl client 1.5 ", client);
    let tx = await client.sendIbcTokens(
        addressInfo.address, receiver,
        token,
        source_port, source_channel,
        undefined, timeout_timestamp, fee, "0x12"
    );
    console.log(tx);
    return tx;
}

export async function issueDenomAndMint(
    orgName,
    sender,
    recipient,
    uri,
    data,
    amount,
    fee,
    adminAddress,
    // orgName, uri, data, count, adminAddres, amount
) {
    fee = web3Obj.utils.toWei(fee.toString());
    let account = await getAccountInfo();
    console.log("lj68 account", account);

    let name = getDenomName(orgName, account.bech32Address);
    console.log("lj68 name", name);
    let denomId = getDenomId(name);
    console.log("lj68 id", denomId);

    const msgs = [];
    let msg = {
        typeUrl: "/uptick.collection.v1.MsgIssueDenom",
        value: [denomId, name, getDenomSchema(), account.bech32Address, data, false, false]
    }
    msgs.push(msg);

    const mintIds = [];
    for (let i = 0; i < amount; i++) {
        let mintId = getNftId();
        mintIds.push(mintId);
        msg = {
            typeUrl: "/uptick.collection.v1.MsgMintNFT",
            value: [mintId, denomId, name, uri, data, account.bech32Address, account.bech32Address]
        }
        msgs.push(msg);
    }
    addSendMsg(msgs, account.bech32Address, adminAddress, fee);

    console.log("lj68 msgs", msgs);
    console.log("lj68 mintIds", mintIds);

    const result = await sendMsgsTx(account.bech32Address, msgs, fee, data);
    result.hash = result.transactionHash;
    //assertIsBroadcastTxSuccess(result)
    console.log("lj68 issueDenomAndMint result", result);

    return {
        txInfo: result,
        denomInfo: msgs
        // transactionHash: result.transactionHash,
        // mintIds: mintIds,
        // denomId: denomId,
        // name: name
    };
}

export async function transferNft(
    nftIds, denomId, name, recipient, fee, adminAddress, memo
    // nftIds, denomId, name, uri, data, recipient, adminAddres, amount
) {

    if (fee > 0) {
        fee = web3Obj.utils.toWei(fee).toString();
    }
    let account = await getAccountInfo();

    const msgs = [];
    for (let i = 0; i < nftIds.length; i++) {
        let msg = {
            typeUrl: "/uptick.collection.v1.MsgTransferNFT",
            value: [nftIds[i], denomId, name, "", memo, account.bech32Address, recipient]
        }
        msgs.push(msg);
    }

    //xxl ##04 add fee must > 0
    if (fee > 0) {
        addSendMsg(msgs, account.bech32Address, adminAddress, fee);
        console.log("lj68 msgs", msgs);
    }

    const result = await sendMsgsTx(account.bech32Address, msgs, fee, memo);
    result.hash = result.transactionHash;
    //assertIsBroadcastTxSuccess(result)
    console.log("lj68 transferNft result", result);
    return result;
}

export async function orderPay(fee, recipient, memo) {
    fee = web3Obj.utils.toWei(fee).toString();
    let account = await getAccountInfo();

    let msgs = [];
    addSendMsg(msgs, account.bech32Address, recipient, fee);

    const result = await sendMsgsTx(account.bech32Address, msgs, fee, memo);
    result.hash = result.transactionHash;
    //assertIsBroadcastTxSuccess(result)
    console.log("lj68 orderPay result", result);
    return result;
}

// export default {
//     getMyBalance, issueDenomAndMint, transferNft, orderPay
// }

async function signingStargateClient() {
    console.log("signingStargateClient",chainId,url);
    const offlineSigner = window.getOfflineSigner(chainId);
    let client = await SigningStargateClient.connectWithSigner(url, offlineSigner);
    return client;
}

async function wasmClient() {
    let client = await CosmWasmClient.connect(url);
    return client;
}

//for erc20 -> demon
export async function convertFromERC20ToDemon() {

    //
    let account = await getAccountInfo();

    //TODO uptick 分支需要把下面3个参数传进来
    //amont ->  to
    let msg = {
        typeUrl: "/uptick.erc20.v1.MsgConvertERC20",
        value: [
            "0x5FD55A1B9FC24967C4dB09C513C3BA0DFa7FF687",
            "1000",
            account.bech32Address,
            "0xf35F83cF2d48BE45C9F6C40Ef1de29B1F3FA1d2c"
        ]
    }

    const result = await sendMsgsTx(account.bech32Address, [msg], 1000, "0x1234");
    //assertIsBroadcastTxSuccess(result)
    console.log("lj68 transferNft result", result);
    return result;


}


async function sendMsgsTx(address, msgs, amount, data) {
    let baseGas = process.env.VUE_APP_IRIS_BASE_GAS
    const fee = {
        amount: [{
            denom: 'auptick',
            amount: amount,
        }],
        gas: baseGas,
    };
    let client = await signingStargateClient();
    const result = await client.sendMsgsTx(address, msgs, fee, data);
    return result;
}

function addSendMsg(msgs, fromAddress, adminAddres, amount) {
    const coins_amount = coins(amount, "auptick");

    let msg = {
        typeUrl: "/cosmos.bank.v1beta1.MsgSend",
        value: {
            fromAddress: fromAddress,
            toAddress: adminAddres,
            amount: coins_amount,
        }
    }
    msgs.push(msg);
}

export async function getAccountInfo() {
    try {

        // keplr 检测
        await window.keplr.enable(chainId);
        // get account
        const account = await window.keplr.getKey(chainId);
        return account;
    } catch (error) {
        console.log(error);
    }
}

function getNftId() {
    let preFix = process.env.VUE_APP_AUPTICK_PREFIX;
    return preFix + getRanHex(16);
}

function getDenomName(name, address) {
    let denomName = name + "_" + Math.floor(Date.now() / 1000) + "_" + address.substr(address.length - 4);
    return denomName;
}

function getDenomId(name) {
    let preFix = process.env.VUE_APP_AUPTICK_PREFIX;
    return preFix + md5(name);
}

function getDenomSchema() {
    let json = { "type": "/auptick.souvenirCard" };
    return JSON.stringify(json);
}


import { MsgTransfer } from "cosmjs-types/ibc/applications/transfer/v1/tx";
export async function ibcTransferFromUptickConvertATOM(
    source_port,
    source_channel,
    amount,
    receiver,
    timeout_timestamp
) {
    try {
        let account = await getAccountInfo();
        let evmAddress = getEvmAddress(account.bech32Address);
// denom的值是 在uptick的代币对应的denom
//         const transferAmount = {
//             denom: 'ibc/C4CFF46FD6DE35CA4CF4CE031E643C8FDC9BA4B99AE598E9B0ED98FE3A2319F9',
//             amount: amount.toString()
//         };
//         let evmAddress = getEvmAddress(account.bech32Address);
// //value 的地址是 20代币的合约地址
//         let msg = {
//             typeUrl: "/uptick.erc20.v1.MsgConvertERC20",
//             value: [
//                atomTokenAddress,
//                 amount.toString(),
//                 account.bech32Address,
//                 evmAddress
//             ]
//         };

//         const transferMsg = {
//             typeUrl: "/ibc.applications.transfer.v1.MsgTransfer",
//             value: MsgTransfer.fromPartial({
//                 sourcePort: source_port,
//                 sourceChannel: source_channel,
//                 sender: account.bech32Address,
//                 receiver: receiver,
//                 token: transferAmount,
//                 timeoutHeight: undefined,
//                 timeoutTimestamp: timeout_timestamp,
//             }),
//         };

 // 支持资产回退，待主网升级放开

 let msg =  {
    typeUrl:"/uptick.erc20.v1.MsgTransferERC20",
    value:[atomTokenAddress,amount,source_port,source_channel,evmAddress,receiver,0,timeout_timestamp,""]
}

        const result = await sendMsgsTx(account.bech32Address, [msg], 10000, "");
        console.log("ibcTransferFromUptickConvert", result);
        return result;
    } catch (error) {
        console.log("ibcTransferFromUptickConvert error",error);
        return { code: 500, msg: error };
    }
}

export async function ibcTransferFromUptickConvertIRIS(
    source_port,
    source_channel,
    amount,
    receiver,
    timeout_timestamp
) {
    try {
        let account = await getAccountInfo();

        let evmAddress = getEvmAddress(account.bech32Address);

        // denom的值是 在uptick的代币对应的denom
        // const transferAmount = {
        //     denom: 'ibc/8F0518015F40A58475E309BFC507E1B59733313C129DE8DB871F87FB701F37F5',
        //     amount: amount.toString()
        // };
 
        //value 的地址是 20代币的合约地址
        //         let msg = {
        //             typeUrl: "/uptick.erc20.v1.MsgConvertERC20",
        //             value: [
        //                 irisTokenAddress,
        //                 amount.toString(),
        //                 account.bech32Address,
        //                 evmAddress
        //             ]
        //         };

        //                 const transferMsg = {
        //     typeUrl: "/ibc.applications.transfer.v1.MsgTransfer",
        //     value: MsgTransfer.fromPartial({
        //         sourcePort: source_port,
        //         sourceChannel: source_channel,
        //         sender: account.bech32Address,
        //         receiver: receiver,
        //         token: transferAmount,
        //         timeoutHeight: undefined,
        //         timeoutTimestamp:timeout_timestamp ,
        //     }),
        // };

    //     let typeUrl = "/uptick.erc20.v1.MsgConvertERC20"
    // let msg =  {
    //     typeUrl:typeUrl,
    //     value:[irisTokenAddress,amount,source_port,source_channel,account.bech32Address,receiver,0,timeout_timestamp,""]
    // }
    //     const result = await sendMsgsTx(account.bech32Address, [msg], 10000, "");

        // 支持资产回退，待主网升级放开

        let msg =  {
            typeUrl:"/uptick.erc20.v1.MsgTransferERC20",
            value:[irisTokenAddress,amount,source_port,source_channel,evmAddress,receiver,0,timeout_timestamp,""]
        }

      
        const result = await sendMsgsTx(account.bech32Address, [msg], 10000, "");
        console.log("ibcTransferFromUptickConvert", result);
        return result;
    } catch (error) {
        console.log("ibcTransferFromUptickConvert error",error);
        return { code: 500, msg: error };
    }
}


export async function ibcTransferFromUptickConvertUSDC(
    source_port,
    source_channel,
    amount,
    receiver,
    timeout_timestamp
) {
    try {
        let account = await getAccountInfo();

        let evmAddress = getEvmAddress(account.bech32Address);

     



         // 支持资产回退，待主网升级放开
    
        let msg =  {
            typeUrl:"/uptick.erc20.v1.MsgTransferERC20",
            value:[usdcTokenAddress,amount,source_port,source_channel,evmAddress,receiver,0,timeout_timestamp,""]
        }

     console.log("ibcTransferFromUptickConvertUSDC",msg);
        const result = await sendMsgsTx(account.bech32Address, [msg], 10000, "");
        console.log("ibcTransferFromUptickConvert", result);
        return result;
    } catch (error) {
        console.log("ibcTransferFromUptickConvert error",error);
        return { code: 500, msg: error };
    }
}



export async function quiryTx(tx) {
    try {
        let client = await signingStargateClient();
        let result = await client.queryClient.tx.getTx(tx);
        return result;
    } catch (e) {
        console.log(e);
    }
}

export async function initWallet() {

    // keplr 检测
    console.log("xxl onload 1");
    window.keplr.experimentalSuggestChain({
        chainId: chainId,
        chainName: 'Uptick-testnet-0.1',
        rpc: url,
        rest: rest,
        stakeCurrency: {
            coinDenom: 'uptick',
            coinMinimalDenom: 'auptick',
            coinDecimals: 18
        },
        bip44: {
            coinType: 60,
        },
        bech32Config: {
            bech32PrefixAccAddr: 'uptick',
            bech32PrefixAccPub: 'uptickpub',
            bech32PrefixValAddr: 'uptickvaloper',
            bech32PrefixValPub: 'uptickvaloperpub',
            bech32PrefixConsAddr: 'uptickvalcons',
            bech32PrefixConsPub: 'uptickvalconspub',
        },
        currencies: [
            {
                coinDenom: 'UPTICK',
                coinMinimalDenom: 'auptick',
                coinDecimals: 18,
            }
        ],
        feeCurrencies: [
            {
                coinDenom: 'UPTICK',
                coinMinimalDenom: 'auptick',
                coinDecimals: 18,
            }
        ],
        coinType: 60,
        gasPriceStep: {
            low: 0.01,
            average: 0.025,
            high: 0.04,
        }
    })
    console.log("xxl onload 2");


}




const { bech32 } = require('bech32');

const toHexString = bytes =>
    bytes.reduce((str, byte) => str + byte.toString(16).padStart(2, '0'), '');


export function getEvmAddress(uptickAddress) {
    let decode = bech32.decode(uptickAddress);
    let array = bech32.fromWords(decode.words);
    let address = toHexString(array);

    return "0x" + address;
}



// web3
// let rpcURL =  process.env.VUE_APP_UPTICK_CONTRACT_RPC  

const rpcURL = window.location.protocol + "//" + window.location.host + "/originode";

const web3 = new Web3(rpcURL)

// let  irisTokenAddress= "0x5FD55A1B9FC24967C4dB09C513C3BA0DFa7FF687";
let  irisTokenAddress = `${process.env.VUE_APP_IRIS_CONTRACT}`
// let  atomTokenAddress= "0xd567B3d7B8FE3C79a1AD8dA978812cfC4Fa05e75";
let  atomTokenAddress =`${process.env.VUE_APP_ATOM_CONTRACT}`
let  usdcTokenAddress =`${process.env.VUE_APP_USDC_CONTRACT}`



console.log('irisTokenAddress -----',irisTokenAddress,atomTokenAddress,usdcTokenAddress);

// The minimum ABI to get ERC20 Token balance
let minABI = [
    // balanceOf
    {
        "constant": true,
        "inputs": [{ "name": "_owner", "type": "address" }],
        "name": "balanceOf",
        "outputs": [{ "name": "balance", "type": "uint256" }],
        "type": "function"
    },
    // decimals
    {
        "constant": true,
        "inputs": [],
        "name": "decimals",
        "outputs": [{ "name": "", "type": "uint8" }],
        "type": "function"
    }
];

let contract = new web3.eth.Contract(minABI, atomTokenAddress);
let contractIris = new web3.eth.Contract(minABI, irisTokenAddress);
let contractUsdc = new web3.eth.Contract(minABI, usdcTokenAddress);

export async function queryBalance() {
    let account = await getAddress();
    let address = account.address;
    
    let evmAddress = getEvmAddress(address);
    const balance = await  contract.methods.balanceOf(evmAddress).call();
     const balanceIris = await  contractIris.methods.balanceOf(evmAddress).call();
     const balanceUsdc = await  contractUsdc.methods.balanceOf(evmAddress).call();
    let evmBalance ={
        IRISBalance:balanceIris,
        AtomBalance :balance,
        UsdcBalance:balanceUsdc
    }
    console.log("evmBalance", balance,balanceIris,balanceUsdc,contractUsdc);
    return evmBalance;
}

export async function convertCosmosNFT2ERC(denomId, nftId) {

    try {
        debugger
        // let irisAccount = await getAccountInfo(irisChainId);
        let uptickAccount = await getAccountInfo();
        let uptickAddress = uptickAccount.bech32Address
        let evmAddress = getEvmAddress(uptickAddress)
        console.log('wwww', "/uptick.erc721.v1.MsgConvertNFT", denomId, nftId, evmAddress, uptickAddress);
        console.log("convertCosmosNFT2ERC evmAddress ", evmAddress);
        console.log("convertCosmosNFT2ERC uptickAddress ", uptickAddress);
        // classId: jspb.Message.getFieldWithDefault(msg, 1, ""),
        // nftId: jspb.Message.getFieldWithDefault(msg, 2, ""),
        // receiver: jspb.Message.getFieldWithDefault(msg, 3, ""),
        // sender: jspb.Message.getFieldWithDefault(msg, 4, ""),
        // contractAddress: jspb.Message.getFieldWithDefault(msg, 5, ""),
        // tokenId: jspb.Message.getFieldWithDefault(msg, 6, "")
        let msg = {
            typeUrl: "/uptick.erc721.v1.MsgConvertNFT",
            value: [
                denomId,
                nftId,
                evmAddress,
                uptickAddress,
                "",//contractAddress
                ""]//tokenId
        }
        console.log('msg --- 111',msg);
        const result = await sendMsgsTx(uptickAddress, [msg], 1000000, "");
        console.log(result)
        console.log(JSON.parse(result.rawLog))
        // debugger
        if (result.code == 0) {

            const logInfo = JSON.parse(result.rawLog)

            let contractAddress = logInfo[0].events[0].attributes[4].value
            let tokenId = logInfo[0].events[0].attributes[5].value
            console.log("contractAddress, tokenId", contractAddress, tokenId)
            
            return {
                "evmNftAddress": contractAddress,
                "evmNftId": tokenId,
                
            };
        } else {
            throw new Error(result.rawLog)
        }
    } catch (error) {
        console.log(error)
        throw new Error(error)
    }
}

export async function convertERC2CosmosNFT(contractAddress, tokenId) {

    try {
debugger
        let uptickAccount = await getAccountInfo();
        let uptickAddress = uptickAccount.bech32Address
        let evmAddress = getEvmAddress(uptickAddress)


        console.log('wwww', "/uptick.erc721.v1.MsgConvertERC721", contractAddress, tokenId, evmAddress, uptickAddress);
        console.log("convertERC2CosmosNFT evmAddress ", evmAddress);
        console.log("convertERC2CosmosNFT uptickAddress ", uptickAddress);

        // contractAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
        // tokenId: jspb.Message.getFieldWithDefault(msg, 2, ""),
        // receiver: jspb.Message.getFieldWithDefault(msg, 3, ""),
        // sender: jspb.Message.getFieldWithDefault(msg, 4, ""),
        // classId: jspb.Message.getFieldWithDefault(msg, 5, ""),
        // nftId: jspb.Message.getFieldWithDefault(msg, 6, "")
        let msg = {
            typeUrl: "/uptick.erc721.v1.MsgConvertERC721",
            value: [
                contractAddress,
                tokenId,
                uptickAddress,
                evmAddress,
                "",//classId
                ""]//nftId
        }

            console.log("msg ---",msg);
        const result = await sendMsgsTx(uptickAddress, [msg], 20000000, "");
        console.log(result)

        if (result.code == 0) {
            const logInfo = JSON.parse(result.rawLog)
            let denomId = logInfo[0].events[0].attributes[2].value
            let nftId = logInfo[0].events[0].attributes[3].value
            console.log("denomId, nftId", denomId, nftId)

            return {
                "uptickNftAddress": denomId,
                "uptickNftId": nftId,
               
            };
        } else {
            throw new Error(result.rawLog)
        }
    } catch (error) {
        console.log(error)
        throw new Error(error)
    }

}
export async function uptick2Iris(denomId, nftId,irisAddress) {
    try {
        debugger

        let uptickAccount = await getAccountInfo();
       

        console.log("uptick2Iris uptickAddress ", uptickAccount.bech32Address);


        // sourcePort: jspb.Message.getFieldWithDefault(msg, 1, ""),
        // sourceChannel: jspb.Message.getFieldWithDefault(msg, 2, ""),
        // classId: jspb.Message.getFieldWithDefault(msg, 3, ""),
        // tokenIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
        // sender: jspb.Message.getFieldWithDefault(msg, 5, ""),
        // receiver: jspb.Message.getFieldWithDefault(msg, 6, ""),
        // timeoutHeight: (f = msg.getTimeoutHeight()) && ibc_core_client_v1_client_pb.Height.toObject(includeInstance, f),
        // timeoutTimestamp: jspb.Message.getFieldWithDefault(msg, 8, 0),
        // memo: jspb.Message.getFieldWithDefault(msg, 9, ""
        let timespan = (Date.now() + 60000) * 1000000;
        let memo ={
            key:"uptick to iris"
        }
        let msg = {
            typeUrl: "/ibc.applications.nft_transfer.v1.MsgTransfer",
            value: [
                "nft-transfer",
                "channel-2",
                denomId,
                [nftId],
                uptickAccount.bech32Address,
                irisAddress,
                [0, 0],
                timespan,
                JSON.stringify(memo)
            ]
        }
        const result = await sendMsgsTx(uptickAccount.bech32Address, [msg], 1000000,JSON.stringify(memo));
        console.log(result)
        if (result.code == 0) {
            // alert("successful ! ");
            return result;
        }
    } catch (error) {
        console.log(error)
        throw new Error(error)
    }
}


export async function addUptickNet() {
    if (!window.getOfflineSigner || !window.keplr) {
        alert("Please install keplr extension");
    } else {
        if (window.keplr.experimentalSuggestChain) {
            try {
                // Keplr v0.6.4 introduces an experimental feature that supports the feature to suggests the chain from a webpage.
                // cosmoshub-3 is integrated to Keplr so the code should return without errors.
                // The code below is not needed for cosmoshub-3, but may be helpful if you’re adding a custom chain.
                // If the user approves, the chain will be added to the user's Keplr extension.
                // If the user rejects it or the suggested chain information doesn't include the required fields, it will throw an error.
                // If the same chain id is already registered, it will resolve and not require the user interactions.
                await window.keplr.experimentalSuggestChain({
                    // Chain-id of the Osmosis chain.
                    chainId: process.env.VUE_APP_UPTICK_CHAIN_ID,
                    // # UPTICK环境
        

                    // The name of the chain to be displayed to the user.
                    chainName:  process.env.VUE_APP_UPTICK_CHAIN_NAME,
                    // RPC endpoint of the chain. In this case we are using blockapsis, as it's accepts connections from any host currently. No Cors limitations.
                    rpc: process.env.VUE_APP_UPTICK_RPC,
                    // REST endpoint of the chain.
                    rest: process.env.VUE_APP_UPTICK_REST,
                    // Staking coin information
                    stakeCurrency: {
                        coinDenom: process.env.VUE_APP_AUPTICK_COINDENOM,
                        coinMinimalDenom: process.env.VUE_APP_AUPTICK_PREFIX,
                        coinDecimals: 18,

                    },

                    bip44: {
                    
                        coinType: 60,
                    },
                   
                    bech32Config: {
                        bech32PrefixAccAddr: "uptick",
                        bech32PrefixAccPub: "uptickpub",
                        bech32PrefixValAddr: "uptickvaloper",
                        bech32PrefixValPub: "uptickvaloperpub",
                        bech32PrefixConsAddr: "uptickvalcons",
                        bech32PrefixConsPub: "uptickvalconspub",
                    },
                    currencies: [
                        {
                            coinDenom: process.env.VUE_APP_AUPTICK_COINDENOM,
                            coinMinimalDenom: process.env.VUE_APP_AUPTICK_PREFIX,
                            coinDecimals: 18,
                        },
                    ],
                    // List of coin/tokens used as a fee token in this chain.
                    feeCurrencies: [
                        {
                            coinDenom: process.env.VUE_APP_AUPTICK_COINDENOM,
                            coinMinimalDenom: process.env.VUE_APP_AUPTICK_PREFIX,
                            coinDecimals: 18,
                        },
                    ],
                  
                    coinType: 60,
                   
                    gasPriceStep: {
                      low: 1 * 100000000000,
                      average: 2 * 100000000000,
                      high: 4 * 100000000000,
                    },
                    
            
                              
                    features:['ibc-transfer','ibc-go', 'eth-address-gen', 'eth-key-sign']
                });

                // location.reload();
            } catch {
                alert("Failed to suggest the chain");
                // location.reload();
            }
        } else {
            alert("Please use the recent version of keplr extension");
        }
    }
}
