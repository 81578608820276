

const {
    SigningStargateClient,
} = require("@cosmjs/stargate");

const cosmChainId = process.env.VUE_APP_COSMOS_CHAIN_ID;
const cosmUrl = window.location.protocol + "//" + window.location.host + "/cosmos";

async function signingStargateClient() {
    const offlineSigner = window.getOfflineSigner(cosmChainId);
    let client = await SigningStargateClient.connectWithSigner(cosmUrl, offlineSigner);
    return client;
}

export async function queryTokenFromCosmos() {
    let accountInfo = await getAccountInfo()
    console.log("wxl ---  accountInfo", accountInfo)
    if (accountInfo) {
        let client = await signingStargateClient();
        let allBal = await client.getAllBalances(accountInfo.bech32Address);
        console.log("xxl allBal : ", allBal);
        return allBal;
    } else {
        return ''
    }
}

export async function getAccountInfo() {
    try {
        // keplr 检测
        await window.keplr.enable(cosmChainId);
        // get account
        const account = await window.keplr.getKey(cosmChainId);
        return account;
    } catch (error) {
        console.log(error);
    }
}

export async function getAddress() {
    const offlineSigner = window.getOfflineSigner(cosmChainId);
    const accounts = await offlineSigner.getAccounts();
    return accounts[0];
}

export async function ibcTransferFromCosmos(
    source_port,
    source_channel,
    token,
    receiver,
    timeout_timestamp
) {
    const fee = {
        amount: [{
            denom: 'uatom',
            amount: 3960,
        }],
        gas: "110000",
    };

    try {
        let client = await signingStargateClient();
        let addressInfo = await getAddress();

        // let sender = "cosmos1gjmwgj0lduw0d75xggy3394ahq8fd2mncle8tn";

        console.log("xxl sendIbcTokens param is ",
            [addressInfo,
                receiver,
                token,
                source_port, source_channel,
                undefined, timeout_timestamp, fee]
        );
        let tx = await client.sendIbcTokens(
            addressInfo.address,
            receiver,
            token,
            source_port, source_channel,
            undefined,timeout_timestamp, fee
        );
        return tx;
    } catch (error) {
        console.log("ibcTransferFromCosmos error",error);
        return { code: 500, msg: error };
    }
}


export async function quiryTx(tx) {
    try {
        let client = await signingStargateClient();
        let result = await client.queryClient.tx.getTx(tx);
        return result;
    } catch (e) {
        console.log(e);
    }
}


export async function addCosmosNet() {
    if (!window.getOfflineSigner || !window.keplr) {
        alert("Please install keplr extension");
    } else {
        if (window.keplr.experimentalSuggestChain) {
            try {

                await window.keplr.experimentalSuggestChain(
                    {
                        // Chain-id of the Osmosis chain.
                        chainId: process.env.VUE_APP_COSMOS_CHAIN_ID,
                  
                        chainName: "Cosmos theta",
                       
                        rpc: process.env.VUE_APP_COSMOS_RPC,
                        rest: process.env.VUE_APP_COSMOS_REST,
                        stakeCurrency: {
                            coinDenom: "ATOM",
                            coinMinimalDenom: "uatom",
                            coinDecimals: 6,
                        },
                      
                        bip44: {
                          
                            coinType: 118,
                        },
                      
                        bech32Config: {
                            bech32PrefixAccAddr: 'cosmos',
                            bech32PrefixAccPub: 'cosmospub',
                            bech32PrefixValAddr: 'cosmosvaloper',
                            bech32PrefixValPub: 'cosmosvaloperpub',
                            bech32PrefixConsAddr: 'cosmosvalcons',
                            bech32PrefixConsPub: 'cosmosvalconspub',
                        },
                      
                        currencies: [{
                            // Coin denomination to be displayed to the user.
                            coinDenom: "ATOM",
                            // Actual denom (i.e. uatom, uscrt) used by the blockchain.
                            coinMinimalDenom: "uatom",
                            // # of decimal points to convert minimal denomination to user-facing denomination.
                            coinDecimals: 6,
                   
                        }],
                        // List of coin/tokens used as a fee token in this chain.
                        feeCurrencies: [{
                            // Coin denomination to be displayed to the user.
                            coinDenom: "ATOM",
                            // Actual denom (i.e. uosmo, uscrt) used by the blockchain.
                            coinMinimalDenom: "uatom",
                            // # of decimal points to convert minimal denomination to user-facing denomination.
                            coinDecimals: 6,
                        
                        }],
                      
                        coinType: 118,
                      
                        gasPriceStep: {
                            low: 0.01,
                            average: 0.025,
                            high: 0.04
                        }
                    });
            } catch {
                alert("Failed to suggest the chain");
                // location.reload();
            }
        } else {
            alert("Please use the recent version of keplr extension");
        }
    }
}